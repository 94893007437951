import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../../components/layout"
import { IMG } from "../../const"
import "../../assets/styles/portfolio-chapter.scss"
import "./_portfolio.scss"
import "../../assets/styles/commons/_dash-before-text.scss"
import PortfolioImagesContainer from "../../components/PortfolioImagesContainer"
import ContactForm from "../../components/contact-form"

const PortfolioCategory = props => {
  const data = useStaticQuery(graphql`
    query {
      portfolioJson {
        name
        categories {
          linkedCategory
          ogImage
        }
        projects {
          title
          additionalDescription
          description
          metaTitle
          tagList
          categoryList
          country
          flagIconUrl
          imageLink
          mainBgImage
          mainPortfolioImage
          secondPortfolioImage
          externalLink
          slug
        }
      }
    }
  `)

  const dataPage =
    props.pageContext.slug === "data_analytics_data_visualization"
  const businessDigitalizationPage =
    props.pageContext.slug === "business_digitalization"
  const recommendationSystemPage =
    props.pageContext.slug === "recommendation_system"

  return (
    <Layout
      pageTitle={props.pageContext.title}
      metaDescription={props.pageContext.metaDescription}
      metaTitle={props.pageContext.metaTitle}
      defaultImage={
        props.pageContext.ogImage
          ? `${IMG.THUMBNAILS}/portfolio/${props.pageContext.ogImage}`
          : ""
      }
    >
      {({
        handleOpenConfirmation,
        isOpenConfirmation,
        setConfirmationOption,
      }) => {
        return (
          <>
            <main className="portfolioChapter">
              <article className="portfolioChapter__list">
                <div className="portfolioHeadline">
                  <div
                    className="portfolioHeadline__bg"
                    id={props.pageContext.slug}
                  >
                    <div className="portfolioHeadline__grid">
                      <p className="portfolioHeadline__textOnTop">
                        check out our
                      </p>

                      {recommendationSystemPage ? (
                        <h1 className="portfolioHeadline__smallText">
                          <strong>Case studies for</strong> Recommendation
                          System
                        </h1>
                      ) : (
                        <h1>
                          <strong>
                            {props.pageContext.displayedName ||
                              props.pageContext.title}
                          </strong>{" "}
                          case studies
                        </h1>
                      )}

                      <div className="portfolioHeadline__rightColumn">
                        <p>
                          {props.pageContext.sideText
                            ? props.pageContext.sideText
                            : "Since 2014, the LaSoft team has been implementing digital transformation services for insurance, telecommunication, human resources, recruitment, marketing, healthcare, real estate, education, sports tech, and other industries."}
                        </p>
                        <Link
                          to={`#cases_${props.pageContext.slug}`}
                          className="goldFatLink portfolioHeadline__anchorLink"
                        >
                          Check our works
                        </Link>
                      </div>
                    </div>
                    <div className="portfolioHeadline__pWrapper">
                      <p
                        className="portfolioHeadline__p"
                        dangerouslySetInnerHTML={{
                          __html: props.pageContext.subtext,
                        }}
                      />
                    </div>
                  </div>
                  <div className="common__dash-before-text">
                    <p>{props.pageContext.description}</p>
                  </div>
                </div>
                <div id={`cases_${props.pageContext.slug}`}>
                  {data.portfolioJson.projects.map(item => {
                    return item.categoryList.map(category => {
                      if (category === props.pageContext.slug) {
                        return (
                          <section
                            className="portfolioChapter__singleProjectSummary"
                            key={`project_${item.slug}`}
                          >
                            <div className="portfolioChapter__singleProjectSummary__text">
                              <div className="portfolioChapter__singleProjectSummary__text__description">
                                <h2>
                                  {item.externalLink ? (
                                    <a
                                      href={item.externalLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {item.title}
                                    </a>
                                  ) : item.slug ? (
                                    <Link to={`/${item.slug}/`}>
                                      {item.title}
                                    </Link>
                                  ) : (
                                    item.title
                                  )}
                                </h2>
                                <ul className="tags">
                                  <li className="flag">
                                    <img
                                      src={`${IMG.INDEX}/${item.flagIconUrl}`}
                                      width="22"
                                      height="16"
                                      alt={`${item.country} flag`}
                                      title={`${item.country} flag`}
                                      loading="lazy"
                                    />
                                  </li>
                                  {item.tagList.map(tag => (
                                    <li key={`tag_${tag.replace(/\s/g, "")}`}>
                                      {tag}
                                    </li>
                                  ))}
                                </ul>
                                <div
                                  className="portfolioChapter__singleProjectSummary__visual mobileOnly"
                                  data-title={item.title}
                                >
                                  <PortfolioImagesContainer
                                    item={item}
                                    dataPage={dataPage}
                                  />
                                </div>

                                <div className="info">
                                  <p>
                                    {businessDigitalizationPage &&
                                    item.additionalDescription
                                      ? item.additionalDescription
                                      : item.description}
                                  </p>
                                </div>
                                {item.externalLink && (
                                  <a
                                    href={item.externalLink}
                                    className="portfolioChapter__seeMore"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Discover project
                                  </a>
                                )}
                                {item.slug && (
                                  <Link
                                    to={`/${item.slug}/`}
                                    className="portfolioChapter__seeMore"
                                  >
                                    Discover project
                                  </Link>
                                )}
                              </div>
                            </div>
                            <div
                              className="portfolioChapter__singleProjectSummary__visual"
                              data-title={item.title}
                            >
                              <PortfolioImagesContainer
                                item={item}
                                dataPage={dataPage}
                              />
                            </div>
                          </section>
                        )
                      } else return null
                    })
                  })}
                </div>
                <ul className="portfolioChapter__watchNext">
                  {props.pageContext.relatedCategories.map(category => (
                    <li key={`related_${category.slug}`}>
                      <Link to={`/portfolio/${category.slug}/`}>
                        <h3 className={`icon__${category.slug}`}>
                          <span>{category.title}</span>
                        </h3>
                        <div>
                          <p>{category.description}</p>
                        </div>
                        <strong className="goldFatLink">Show More</strong>
                      </Link>
                    </li>
                  ))}
                </ul>
              </article>
            </main>
            <div className="portfolioContactForm">
              <div>
                <ContactForm
                  formStatID="CommonFooter"
                  handleOpenConfirmation={handleOpenConfirmation}
                  isOpenConfirmation={isOpenConfirmation}
                  setConfirmationOption={setConfirmationOption}
                />
              </div>
            </div>
          </>
        )
      }}
    </Layout>
  )
}

export default PortfolioCategory
